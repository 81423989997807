import { auth } from '../firebase'; 
import { useState, useEffect } from 'react';
import image from '../PngItem_46390.png'
import Brand from '../Brand.png'
import '../Form/Form.css'

const Header = () => {

    const [uid, setUid] = useState("");
    const [user, setUser] = useState(null);
    const [username, setUsername] = useState("");
    const [usermail, setUsermail] = useState("");
    const [userId, setUserId] = useState("vish123");

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
          if (currentUser) {
            setUser(currentUser);
            setUid(currentUser.uid);
            setUserId(currentUser.uid);
            setUsername(currentUser.displayName) 
            setUsermail(currentUser.email)
  
          }
        });
  
  
  
    
        return () => unsubscribe();
      }, []);




    return ( <div style={{paddingLeft:"3vw", paddingTop:"3vw",width:"100%", marginLeft:'20%'}}>
    
    {user ? ( <>    <div style={{display:"flex"}} className='brandname'>
    <div style={{width:"7vw"}}>
   <img src={Brand} style={{borderRadius:"10px", width:"100%"}}/>
   </div>&nbsp;
   <h2 style={{fontWeight:"20px"}}>&nbsp;Customer Onboarding</h2>
   
   </div>
   
   <div class="card text-bg-white mb-3" style={{maxWidth: "max-content", boxShadow:"0 0 1px rgba(0, 0, 0, 0.528)", marginBottom:"5vh"}}>
  <div class="card-body">
    <p class="card-text"><div style={{display:"flex"}}><h5 style={{color:"#14183e"}}>{username} &nbsp;&nbsp;</h5> <p>|&nbsp;&nbsp; {usermail}</p></div> <div style={{display:"flex", fontSize:"0.8rem", color:"grey"}} ><p>User ID&nbsp;&nbsp;:&nbsp;&nbsp;</p> <p>{uid}</p></div></p>
  </div>
</div>


</>) : (<><br/><br/></>)}
    
    </div> );
}
 
export default Header;